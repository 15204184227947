// extracted by mini-css-extract-plugin
export var center = "ResumePage-module--center--9b0dd";
export var centerButton = "ResumePage-module--centerButton--df016";
export var centerTitle = "ResumePage-module--centerTitle--bbb03";
export var content = "ResumePage-module--content--dfefc";
export var content2 = "ResumePage-module--content2--e4fd6";
export var footDescrip = "ResumePage-module--footDescrip--5a6f4";
export var footer = "ResumePage-module--footer--ef519";
export var overall = "ResumePage-module--overall--a999b";
export var resumeStyle = "ResumePage-module--resumeStyle--64bc2";
export var title = "ResumePage-module--title--acc5f";
export var title3 = "ResumePage-module--title3--2ba8f";